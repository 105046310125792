<template>
    <div class="wrapper">
		<div class="splash-screen">
			<div class="splash-container">
				<div class="splash-double-bounce1"></div>
				<div class="splash-double-bounce2"></div>
			</div>
		</div>
        <!-- HEADER / MENU -->
        <header class="header1 header-megamenu">
            <div class="topbar">
                <div class="container">
                    <div class="row">
                        <div class="col-md:col-8 col-sm-8 hidden-xs">
                            <div class="newsfeed">
                                <!-- <span>BREAKING:</span>
                                <div class="news-carousel">
                                    <div class="item"><a href="post_page_01.html">Virgin faces data protection probe over Corbyn train row</a></div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md:col-4 col-sm-4 col-xs-12">
                            <div class="pull-right account-options">
								<div v-if="$store.getters.isAuth">
									<a href="/dashboard"><span class="pi pi-sign-in"></span> Dashboard</a>
								</div>
                                <div v-else>
									<a href="/login"><span class="pi pi-sign-in"></span> Masuk</a>
									<a href="/auth/register-member"><span class="pi pi-sign-in"></span> Daftar</a>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="navbar-header">
                <div class="container">
                    <router-link to="/" class="navbar-brand">
						<img :src="logo" class="img-responsive" alt=""/>
					</router-link>
                    <div class="ad-banner pull-right hidden-xs">
                        <!-- <a href="#"><img src="/assets/images/ads/728x90.jpg" class="img-responsive" alt=""/></a> -->
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="container">
				<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
					<span>Menu</span>
					<span class="pi pi-bars fa-navicon"></span>
				</button>
                <div class="search-wrap2">
                    <form>
                        <input type="text" placeholder="Search by typing">
                        <div class="sw2-close"><i class="pi pi-times" style="fontSize: 1.5rem"></i></div>
                    </form>
                </div>

                <div id="navbar" class="navbar-collapse collapse">
                    <ul class="nav navbar-nav">
						<li v-for="(menu, i) in menus" :key="i" :class="{ 'dropdown dropdown-v2': menu.items }">
							<router-link v-if="menu.type == 'router'" :to="menu.to" :class="{ 'dropdown-toggle': menu.items }"> {{ menu.label }} <span v-if="menu.items" class="pi pi-angle-down"></span></router-link>
							<a v-else-if="menu.type == 'link'" :href="menu.to">{{ menu.label }}</a>
							<ul v-if="menu.items" class="dropdown-menu">
                                <li v-for="(item, j) in menu.items" :key="j">
									<router-link v-if="item.type == 'router'" :to="item.to">{{ item.label }}</router-link>
									<a v-else-if="item.type == 'link'" :href="item.to">{{ item.label }}</a>
								</li>
                            </ul>
						</li>
                        <li class="pull-right hidden-xs">
                            <div class="search-trigger search-trigger2"><i class="pi pi-search" style="fontSize: 1.5rem"></i></div>
                        </li>
                    </ul>

                </div>
            </div>
        </header>
        <!-- // HEADER / MENU -->

        <router-view :key="$route.path" :settings="settings" :sidebar="sidebar"></router-view>
	
		<!-- FOOTER -->
		<footer class="bg-dark footer1 padding-top-60">
			<div class="container">
				<div class="row margin-bottom-30">
					<div class="col-md:col-4 col-sm-4 margin-bottom-30 footer-info">
						<router-link to="/"><img :src="logo" class="footer-logo" alt=""/></router-link>
						<p>{{ settings.general.meta_description }}</p>
						<!-- <span><i class="fa fa-map-marker"></i> 1610 Connecticut Avenue, NW, Suite 500</span>
						<span><i class="fa fa-envelope"></i> <a href="mailto:info@watcher.com">info@watcher.com</a></span>
						<span><i class="fa fa-phone"></i> +1-202-555-0113</span> -->
					</div>

					<div class="col-md:col-4 col-sm-4 margin-bottom-30 footer-trending">
						<h5>Tentang Kami</h5>
						<ul class="trending">
							<li v-for="(menu, i) in menus[1].items" :key="i">
								<h4><router-link :to="menu.to">{{ menu.label }}</router-link></h4>
							</li>
						</ul>
					</div>

					<div class="col-md:col-4 col-sm-4 margin-bottom-30 footer-follow">
						<h5>Follow</h5>
						<div class="footer-newsletter">
							<form action="" id="invite1" method="POST">
								<i class="fa fa-envelope"></i>
								<input type="email" placeholder="Email address" class="e-mail" name="email" id="address1" data-validate="validate(required, email)">
								<button type="submit">Subscribe</button>
							</form>
							<span>Don't worry we hate spam as much as you do</span>
							<div id="result1"></div>
						</div>
						<div class="footer-social">
							<a href="#"><i class="pi pi-facebook"></i></a>
							<a href="#"><i class="pi pi-twitter"></i></a>
							<a href="#"><i class="pi pi-linkedin"></i></a>
							<a href="#"><i class="pi pi-instagram"></i></a>
							<a href="#"><i class="pi pi-youtube"></i></a>
						</div>
					</div>
				</div>

				<!-- FOOTER COPYRIGHT -->
				<div class="footer-bottom">
					<div class="row">
						<div class="col-md:col-8 col-sm-12" v-html="settings.general.footer">
							
						</div>
						<div class="col-md:col-4 col-sm-12">
							<!-- <p class="pull-right">Powered by KADECO</p> -->
						</div>
					</div>
				</div>
			</div>
		</footer>
		<!-- // FOOTER -->

	</div>
</template>

<script>
export default {
	props: ['settings'],
	data() {
        return {
			api: '/api/landing',
            menus : [
                {label: 'Home', to: '/', type: 'router'},
                {
                    label: 'Tentang',  to: '#', type: 'router',
                    items: []
                },
				// {
                //     label: 'Kerjasama',  to: '#', type: 'router',
                //     items: [
                //         {label: 'Industri', to: '/', type: 'router'},
				// 		{label: 'Perguruan Tinggi', to: '/', type: 'router'},
                //     ]
                // },
				// {
                //     label: 'Berita',  to: '/berita', type: 'router',
                // },
				{
                    label: 'Kegiatan',  to: '/kegiatan', type: 'router',
                },
				{label: 'Daftar', to: '/register-member', type: 'link'},
            ],
			sidebar: {}
        }
    },
	computed: {
        logo() {
            return (this.settings.general.logo) ? process.env.VUE_APP_ASSETS_FOLDER + '/' + this.settings.general.logo : process.env.VUE_APP_DEFAULT_LOGO;
        }
    },
    methods: {
		getPageURL(){
			this.$Progress.start();
			this.$httpAuth.get(`${this.api}/get/page-url`)
			.then((response) => {
				this.$Progress.finish();
				this.menus[1].items = response.data.map((item) => {
					return {
						label: item.title,
						to: `/p/${item.id}/${item.slug}`,
						type: 'router'
					}
				})
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getSidebar(){
			this.$Progress.start();
			this.$httpAuth.get(`${this.api}/get/sidebar`)
			.then((response) => {
				this.$Progress.finish();
				this.sidebar = response.data;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		}
	},
	beforeCreate(){},
    created(){
		this.getPageURL();
		this.getSidebar();
	},
	mounted(){
		require('@/assets/js/bootstrap.min.js');
		// require('@/assets/js/slick.min.js');
		// setTimeout(() => {
		// 	require('@/assets/js/main.js');
		// }, 500)
		setTimeout(() => {
			$('.splash-screen').remove();
		}, 1000);
	},
	unmounted(){}
}
</script>

<style lang="scss">
@import url('//fonts.googleapis.com/css?family=Roboto:100,300,300i,400,400i,500,700,700i,900');
@import '../assets/css/bootstrap.min.lazy.css';
@import '../assets/css/ts.css';
@import '../assets/css/slick.css';
@import '../assets/css/style.css';

.footer-bottom p a {
	color: #0d89ec;
}
.footer-logo {
	height: 100px;
}
.next.slick-arrow {
	margin-right: -6px;
}
.trending li {
	padding-left: unset !important;
}
</style>